
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MyMarketsDomainField extends Vue {
  @Prop() value!: string;

  handleInput(e: any): void {
    this.$emit('input', e.target.value);
  }
}
