
import { Component, Vue } from 'vue-property-decorator';
import WButton from '@/components/UI/WButton.vue';
import MyMarketsList from '@/components/MyMarkets/MyMarketsList.vue';
import MyMarketsAddModal from '@/components/MyMarkets/MyMarketsAddModal.vue';

@Component({
  components: {
    WButton,
    MyMarketsList,
    MyMarketsAddModal
  },
})
export default class MyMarketsComponent extends Vue {
  private showAddModal = false;

  openModal(): void {
    this.showAddModal = true;
  }

  closeModal(): void {
    this.showAddModal = false;
  }
}
