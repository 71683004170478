
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ArrowDownIcon } from '@/components/UI/icons';
import IList from '@/types/IList';

@Component({
  components: { ArrowDownIcon }
})
export default class WSelect extends Vue {
  @Prop() label!: string;
  @Prop() list!: Array<IList>;
  @Prop() selected!: IList;

  private show = false;
  private eventListener: any = false;

  created(): void {
    this.eventListener = document.addEventListener('click', (e: any) => {
      if (typeof e.target?.className === 'string') {
        if (e.target?.className?.indexOf('w-select') === -1) this.show = false;
      }
    });
  }
}
