
import { Component, Vue } from 'vue-property-decorator';
import WButton from '@/components/UI/WButton.vue';

@Component({
  components: { WButton },
})
export default class MyMarketsList extends Vue {
  private myMarketsHead = [
    { id: 0, title: '№', name: 'num' },
    { id: 1, title: 'Название', name: 'name' },
    { id: 2, title: 'Адрес', name: 'adress' },
    { id: 3, title: 'Действие', name: 'action' },
  ];
  private myMarkets = [
    { num: '12345', name: 'Дипломная работа', adress: 'http://www.work5.ru/services', action: '', },
    { num: '12345', name: 'Дипломная работа', adress: 'http://www.work5.ru/services', action: '', },
  ];
}
