
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CloseIcon, ExclamationPointIcon } from '@/components/UI/icons';
import WButton from '@/components/UI/WButton.vue';
import WSelect from '@/components/UI/WSelect.vue';
import MyMarketsDomainField from '@/components/MyMarkets/MyMarketsDomainField.vue';
import IList from '@/types/IList';

@Component({
  components: {
    CloseIcon,
    ExclamationPointIcon,
    WButton,
    WSelect,
    MyMarketsDomainField
  }
})
export default class MyMarketsAddModal extends Vue {
  @Prop() show!: boolean;

  private templateList = [
    { id: 0, name: 'Стандартный' },
    { id: 1, name: 'Не стандартный' }
  ];
  private selectedTemplate: IList = { id: 0,  name: 'Стандартный' };
  private colorSchemeList = [
    { id: 0, name: 'Классическая' },
    { id: 1, name: 'Не классическая' },
  ];
  private selectedColorScheme = { id: 0, name: 'Классическая' };
  private domain = '';

  changeTemplate(val: IList): void {
    this.selectedTemplate = val;
  }

  changeColorScheme(val: IList): void {
    this.selectedColorScheme = val;
  }
}
